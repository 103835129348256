.loader {
            position: fixed;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background-color: #ffffff;
        }
        .loader img{
            display:block;
			margin: 20% auto;
        }
		
.youtube-video {
  position: relative;
  cursor: pointer;
}

.play-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
}

.play-button-icon {
  font-size: 2em;
}

.play { 
  background: red;
  border-radius: 50% / 10%;
  color: #FFFFFF;
  font-size: 0.45em; /* change this to change size */
  height: 3.1em;
  margin: 20px auto;
  padding: 0;
  position: relative;
  text-align: center;
  text-indent: 0.1em;
  transition: all 150ms ease-out;
  width: 4em;
}

.play:hover {
  background: darkorange;
}

.play::before { 
  background: inherit;
  border-radius: 5% / 50%;
  bottom: 9%;
  content: "";
  left: -5%;
  position: absolute;
  right: -5%;
  top: 9%;
}

.play::after {
  border-style: solid;
  border-width: 1em 0 1em 1.732em;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  content: ' ';
  font-size: 0.75em;
  height: 0;
  margin: -1em 0 0 -0.75em;
  top: 50%;
  position: absolute;
  width: 0;
}



.video-placeholder {
  position: relative;
  width: 100%;
  height: 300px; /* Fixed height */
  overflow: hidden; /* Hide overflow to crop the image */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.video-placeholder img {
  width: 100%; /* Ensure the image fills the container width */
  height: auto; /* Allow the image to scale proportionally */
}